<template>
    <div>
        <div class="search">
            <el-form ref="pageInfo" :model="pageInfo" label-width="110px">

                        <el-form-item label="供应商编号：">
                            <el-input v-model="pageInfo.supplyCode"></el-input>
                        </el-form-item>

                        <el-form-item label="供应商账号：">
                            <el-input  v-model="pageInfo.userName"></el-input>
                        </el-form-item>

                        <el-form-item label="供应商名称：" style="margin-right: 30px">
                            <el-input  v-model="pageInfo.companyName"></el-input>
                        </el-form-item>

                    <!-- <el-col :span="4">
                        <el-form-item label="提交时间：">
                            <el-date-picker
                                v-model="pageInfo.updateTime"
                                style="width: 150px;"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col> -->
                    <!-- <el-col :span="4">
                        <el-form-item label="审核状态：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col> -->
                    <div style="margin-bottom: 22px; display: inline-block; vertical-align: middle;">
                        <el-button type="primary" @click="searchHandle" icon="el-icon-search">查询</el-button>
                        <a class="clear cp" @click="clearSearch">清空</a>
                    </div>

            </el-form>
        </div>
        <div style="height: 20px; background: #F6F7F9;"></div>
        <div class="content">
            <h4>供应商列表</h4>
            <!-- <el-tabs v-model="activeName" @tab-click="handleClickTab">
                <el-tab-pane label="全部" name=""></el-tab-pane>
                <el-tab-pane label="已审核" name="3"></el-tab-pane>
                <el-tab-pane label="未审核" name="2"></el-tab-pane>
                <el-tab-pane label="已拒绝" name="2"></el-tab-pane>
            </el-tabs> -->

            <div style="padding: 0 24px">
                <el-table border :data="tableData">
                    <el-table-column
                                type="selection"
                                width="55">
                        </el-table-column>
                        <el-table-column prop="supplyCode" label="供应商编号"></el-table-column>
                        <el-table-column prop="userName" label="供应商账号"></el-table-column>
                        <el-table-column prop="userChineseName" label="联系人"></el-table-column>
                        <el-table-column prop="companyName" label="供应商名称"></el-table-column>
                        <el-table-column prop="shopName" label="店铺名称"></el-table-column>
                        <!-- <el-table-column prop="companyName" label="商家类型"></el-table-column>
                        <el-table-column prop="updateTime" label="审核时间"></el-table-column> -->
                        <!-- <el-table-column prop="name" label="审核状态">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status == 1" class="flex  f_ai_c" style="color: #F53333;">
                                    <div style="border-color: #DCDE43" class="wRound mr5 flex f_jc_c f_ai_c bs—_b">
                                        <div style="background: #DCDE43" class="nRound"></div>
                                    </div>
                                    <div style="color: #DCDE43">审批中</div>
                                </div>
                                <div v-if="scope.row.status == 3" class="flex  f_ai_c" style="color: #F53333;">
                                    <div style="border-color: #0CB65F" class="wRound mr5 flex f_jc_c f_ai_c bs—_b">
                                        <div  style="background: #0CB65F" class="nRound"></div>
                                    </div>
                                    <div style="color: #0CB65F;">通过</div>
                                </div>
                                <div v-if="scope.row.status == 2" class="flex  f_ai_c" style="color: #F53333;">
                                    <div class="wRound flex mr5 f_jc_c f_ai_c bs_b">
                                        <div class="nRound "></div>
                                    </div>
                                    <div>拒绝</div>
                                </div>
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="name" label="操作">
                            <template slot-scope="scope">
                                <el-button  @click="goDetails(scope.row, 1)" type="text" size="small">查看</el-button>
                                <el-button type="text" @click="goDetails(scope.row, 3)" size="small">授权</el-button>
                            </template>
                        </el-table-column>
                </el-table>
            </div>

            <div style="text-align: right">
                <div style="margin-top: 12px; display: inline-block">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageInfo.pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageInfo.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        
        <div class="zw"></div>
        <div class="footer"></div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            id: '',// 记录id
            userId: '',
            dialogdFormVisible: false,
            activeName: '',
            tableData: [],
            pageInfo: {
                status: '3',
                pageNo: 1,
                pageSize: 10,
                total: 0,
                supplyCode: '', //供应商编号
                userName: '', //供应商账号 
                companyName: '', //供应商名称
                updateTime: '' //提交时间
            },
            approveReason: ''
        }
    },
     mounted(){
         this.search()
    },
    methods:{
        search(){
            // let params = {
            //     pageNo: this.pageInfo.pageNo,
            //     pageSize: this.pageInfo.pageSize,
            // };
            this.qa.queryForPageNoZeroList(this.pageInfo).then(res => {
                if(res.respCode == "0000") {
                    this.tableData = res.data;
                    this.pageInfo.total = res.total;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        searchHandle() {
            this.search()
        },
        handleClickTab(e,event) {
            if( this.activeName == '0') {
                this.pageInfo.status = ''
            }else {
                this.pageInfo.status = this.activeName
            }
            this.search()
        },
        handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.search()
        },
        handleCurrentChange(page) {
            this.pageInfo.pageNo = page;
            this.search()
        },
        clearSearch() {
            this.pageInfo.supplyCode = ''
            this.pageInfo.companyName = ''
            this.pageInfo.updateTime = ''
            this.pageInfo.userName = ''
            this.search()
        },
        //查看详情
        goDetails(row, type) {
            this.$router.push({
                path: '/supplierBrandDetails',
                query: {
                    userId: row.userId,
                    id: row.id,
                    type: type
                }
            });
        },

    }
}
</script>

<style scoped>
  .search {
      background: #fff;
      padding: 24px;
      padding-bottom: 0;
  }
  .search >>> .el-form .el-form-item {
      display: inline-block;
      vertical-align: middle;
  }
  .search >>> .el-form .el-input__inner {
      height: 34px;
  }
  .search >>> .el-button {
      padding: 9px 20px;
  }
  .search >>> .el-col {
      height: 34px;
  }
  .search >>> .el-form-item__label {
      line-height: 34px;
  }
  .search >>> .el-form-item__content {
      line-height: 34px;
  }
  .content >>> .el-tabs__nav-wrap {
      padding: 0 28px;
  }
  .content >>> .el-tabs .is-active {
      background: rgba(25, 145, 252, 0.1);
  }
  .content >>> .el-tabs .el-tabs__item {
      /* padding: 0; */
      

  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 20px;
  }
  .clear {
      font-size: 14px;
      color: #8C9099;
      margin-left: 24px;
  }
  .content {
      /* padding: 24px; */
      padding: 24px 0;
      background: #fff;

  }
  .content >h4 {
      padding: 0 24px;
      margin-bottom: 20px;
  }

  .wRound {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #F53333;
  }
  .nRound {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #F53333;
  }
</style>